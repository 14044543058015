import BigNumber from 'bignumber.js'
import { DatePickerPortal } from 'components/DatePicker'
import useEagerConnect from 'hooks/useEagerConnect'
import React, { lazy } from 'react'
import { Route, Router, Switch } from 'react-router-dom'
import { usePollBlockNumber } from 'state/block/hooks'
import { usePollCoreFarmData } from 'state/farms/hooks'
import { useSaveReferrer } from 'state/hooks'
import { useFetchProfile } from 'state/profile/hooks'
import PageLoader from './components/Loader/PageLoader'
import Menu from './components/Menu'
import SuspenseWithChunkError from './components/SuspenseWithChunkError'
import { ToastListener } from './contexts/ToastsContext'
import history from './routerHistory'
import GlobalStyle from './style/Global'
import ResetCSS from './style/ResetCSS'


// Route-based code splitting
// Only pool is included in the main bundle because of it's the most visited page

// BOT
const DogwatcherV4Pro = lazy(() => import('./views/DogwatcherV4Pro'))
const TransactionDetail = lazy(() => import('./views/TransactionDetail'))
// BOT

const NotFound = lazy(() => import('./views/NotFound'))

// This config is required for number formatting Membership   
BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

const App: React.FC = () => {
  usePollBlockNumber()
  useEagerConnect()
  useFetchProfile()
  usePollCoreFarmData()
  useSaveReferrer()

  return (
    <Router history={history}>
      <ResetCSS />
      <GlobalStyle />
      {/* <GlobalCheckClaimStatus excludeLocations={['/collectibles']} /> */}
      <Menu>
        <SuspenseWithChunkError fallback={<PageLoader />}>
          <Switch>
            <Route path="/" exact>
              <DogwatcherV4Pro />
            </Route>
            {/* Using this format because these components use routes injected props. We need to rework them with hooks */} 
            {/* <Route exact strict path="/swap" component={Swap} />
            <Route exact strict path="/swap/:outputCurrency" component={RedirectToSwap} /> */}
            <Route exact path="/tokentransaction/:idToken/:tokenName" component={TransactionDetail} />

            {/* 404 */}
            <Route component={NotFound} />
          </Switch>
        </SuspenseWithChunkError>
      </Menu>
      {/* <EasterEgg iterations={2} /> */}
      <ToastListener />
      <DatePickerPortal />
    </Router>
  )
}

export default React.memo(App)
